import React, { useState } from 'react'
import './Release.css'

const Release = () => {
  const [isMobile] = useState(window.innerWidth < 960)
  const [clickRelease, setClickRelease] = useState(false)
  const [classFlipper, setClassFlipper] = useState('flipper')

  const clickFlip = () => {
    if (isMobile && clickRelease) {
      setClickRelease(false)
      setClassFlipper('frontflip')
    } else if (isMobile && !clickRelease) {
      setClickRelease(true)
      setClassFlipper('flipper')
    }
  }

  return (
    <div id="release" className="release-content">
      <h2>Release</h2>
      <div className="flip-container">
        <div className={isMobile ? classFlipper : 'flipper'}>
          <div
            className={isMobile ? 'front' : 'front screen'}
            onClick={clickFlip}
          >
            <figure>
              <img height="250" src="assets/images/cassete.jpg" alt="release-pic" />
              <figcaption>H A R V A L T</figcaption>
            </figure>
          </div>
          <div className="back" onClick={clickFlip}>
            Banda formada em Divinópolis-MG focada no estilo que consagrou os anos 90, HardRock e GlamRock. 
            Tocando as melhores versões de músicas que marcaram época e fizeram inesquecíveis o estilo.
            Esta banda GlamRock irá transportá-lo de volta no tempo com seus riffs poderosos e hinos marcantes.
            Prepare-se para agitar e liberar seu rebelde interior com Harvalt!
          </div>
        </div>
      </div>
    </div>
  )
}

export default Release