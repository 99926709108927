export const MembersInfo = [
  {
    id: 'vocal',
    name: 'Leandro Nascimento',
    role: 'Vocalista',
    desc: '',
    instagram: 'https://www.instagram.com/lean_nasc1/',
  },
  {
    id: 'lead_guitar',
    name: 'Alex Amaral',
    role: 'Guitarra solo',
    desc: '',
    instagram: 'https://www.instagram.com/alexsamaralns1/',
  },
  {
    id: 'rythm_guitar',
    name: 'Thulio Carvalho',
    role: 'Guitarra base',
    desc: '',
    instagram: 'https://www.instagram.com/vet.thulio/',
  },
  {
    id: 'drums',
    name: 'Vinicius Lucena',
    role: 'Baterista',
    desc: '',
    instagram: 'https://www.instagram.com/vini_lucena_batera/',
  },
];
