import React from 'react'
import './Loading.css'

const Loading = () => {
  return (
    <div className="loading-content">
      <img src="assets/images/logo_green.png" alt="loading" />
    </div>
  )
}

export default Loading
