export const MenuItems = [
  {
    id: 'release',
    title: 'Release',
    path: '',
  },
  {
    id: 'members',
    title: 'Integrantes',
    path: '',
  },
  {
    id: 'midia',
    title: 'Mídia',
    path: '',
  },
  /* {
    id: 'schedule',
    title: 'Agenda',
    path: '',
  }, */
  {
    id: 'contact',
    title: 'Contato',
    path: '',
  },
]