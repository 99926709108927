import './Content.css'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
 
import Home from '../../pages/home/Home'
// import NotFound from '../../views/examples/NotFound'
 
const Content = props => (
  <main className="Content">
    <Routes>
      <Route exact path="/" element={<Home />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  </main>
)
 
export default Content