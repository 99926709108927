import React from 'react'
import { MenuItems } from '../header/navbar/MenuItems'
import './Footer.css'

const Footer = () => {
  const navigation = (id) => {
    if (id !== 'presentation') {
      const elem = document.getElementById(id)

      elem.scrollIntoView({ behavior: 'smooth' })
    } else {
      const elem = document.getElementById('header-navbar')

      elem.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="footer-content">
      <div className="space-footer">
        <div className="columns-footer">
          <span>Institucional</span>
          <button
            type="button"
            className="footer-btn"
            onClick={() => navigation('presentation')}
          >
            Início
          </button>
          {MenuItems.map((item, index) => {
            return (
              <button
                key={`btn-footer-${index}`}
                type="button"
                className="footer-btn"
                onClick={() => navigation(item.id)}
              >
                {item.title}
              </button>
            )
          })}
        </div>
      </div>
      <div className="space-footer">
        <div className="columns-footer">
          <span>Harvalt</span>
          <p>Autenticidade musical</p>
          <p>Performance marcante</p>
          <p>Essência original do Rock</p>
        </div>
      </div>
      <div className="space-footer">
        <div className="columns-footer">
          <span>Parceiros</span>
          <a href="https://www.instagram.com/studiosintoniart/" target="_blank" rel="noreferrer">Estúdio Sintoniart</a>
        </div>
      </div>
      <div className="space-footer">
        <div className="columns-footer">
          <span>Desenvolvido por</span>
          <a href="https://www.instagram.com/radaxian_solution/" target="_blank" rel="noreferrer">Radaxian Solution</a>
        </div>
      </div>
    </div>
  )
}

export default Footer
