import React from 'react'
import Contact from '../../pages/home/components/contact/Contact'
import Members from './components/members/Members'
import Midia from './components/midia/Midia'
import Presentation from './components/presentation/Presentation'
import Release from './components/release/Release'
import './Home.css'

const Home = () => {
  return (
    <div className="home-content">
      <section>
        <Presentation />
      </section>
      <section>
        <Release />
      </section>
      <section>
        <Members />
      </section>
      <section>
        <Midia />
      </section>
      <section>
        <Contact />
      </section>
    </div>
  )
}

export default Home
