import React from 'react'
import Content from './components/content/Content'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import Navbar from './components/header/navbar/Navbar'
import Footer from './components/footer/Footer'

const App = (props) => {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Content />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
