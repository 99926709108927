import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import emailjs from 'emailjs-com'
import './Contact.css'

const Contact = () => {
  const [isMobile] = useState(window.innerWidth < 960)
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: '',
  })
  const [disabledBtn, setDisabledBtn] = useState(true)

  useEffect(() => {
    if (formData.name.length > 0 && formData.phone.length > 0 && formData.message.length > 0) {
      setDisabledBtn(false)
    } else {
      setDisabledBtn(true)
    }
  }, [formData])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Configuração do serviço de e-mail
    const serviceID = process.env.REACT_APP_SERVICE_ID
    const templateID = process.env.REACT_APP_TEMPLATE_ID
    const userID = process.env.REACT_APP_USER_ID

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('E-mail enviado com sucesso:', response)
      })
      .catch((error) => {
        console.error('Erro ao enviar e-mail:', error)
      })

    setFormData({
      name: '',
      phone: '',
      message: '',
    })
  }

  return (
    <div id="contact" className="contact-content">
      <h2>Contato</h2>
      <div>
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Nome:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="phone">Telefone:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="text-area-contact">
              <label htmlFor="message">Mensagem:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div>
              <button
                className={disabledBtn ? 'btn-default-disabled' : 'btn-default'}
                type="submit"
                disabled={disabledBtn}
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
        <div className="content-right">
          <div>
            <a className="icon-social-media-contact" href="https://www.instagram.com/harvaltband/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} size={isMobile ? 'sm' : 'xl'} style={{ color: "#c0ff00", }} />
              <span className={isMobile ? 'instagram-perfil' : ''}>@harvaltband</span>
            </a>
          </div>
          <div>
            <a className="icon-social-media-contact" href="https://www.youtube.com/@Harvaltband" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faYoutube} size={isMobile ? 'sm' : 'xl'} style={{ color: "#c0ff00", }} />
              <span className={isMobile ? 'instagram-perfil' : ''}>Harvalt Band</span>
            </a>
          </div>
          <div>
            <div className="phone-contact">
              <FontAwesomeIcon
                className="icon-whatsapp-media-contact"
                icon={faWhatsapp}
                size="xl"
              />
              <a className="phone-number" href="https://wa.me/37999783780" target="_blank" rel="noreferrer">
                <span>37 99978-3780</span>
              </a>
              {/* <a className="phone-number" href="https://wa.me/37999866095" target="_blank" rel="noreferrer">
                <span>37 99986-6095</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
