import React, { useState } from 'react'
import axios from 'axios'
import YouTube from 'react-youtube'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Slide from '@mui/material/Slide'
import Loading from '../../../../components/loading/Loading'
import Carousel from 'react-material-ui-carousel'
import './Midia.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

let midiaType = ''
let videoElement = null
const videoLinks = [
  {
    id: '9hrIFJDg1Bw',
    link: 'https://www.youtube.com/embed/9hrIFJDg1Bw?si=_Pz34pxrpsgtArjW',
  },
  {
    id: 'AR9QQzv9Cec',
    link: 'https://www.youtube.com/watch?v=AR9QQzv9Cec',
  },
  {
    id: 'IbtPmowd8wQ',
    link: 'https://www.youtube.com/watch?v=IbtPmowd8wQ',
  },
  {
    id: '0DUk_eqHFG4',
    link: 'https://www.youtube.com/watch?v=0DUk_eqHFG4',
  },
  {
    id: 'btCRhgN09BE',
    link: 'https://www.youtube.com/embed/btCRhgN09BE',
  },
  {
    id: 'vMLZUPHkULg',
    link: 'https://www.youtube.com/embed/vMLZUPHkULg',
  },
  {
    id: '0HUmCTyDXM8',
    link: 'https://www.youtube.com/embed/0HUmCTyDXM8',
  },
  {
    id: 'fn3Yid8ajLQ',
    link: 'https://www.youtube.com/embed/fn3Yid8ajLQ',
  }
]

const Midia = () => {
  const [isMobile] = useState(window.innerWidth < 960)
  const [starAnimationClass, setStarAnimationClass] = useState(['', ''])
  const [openDialog, setOpenDialog] = useState(false)
  const [imagesInfo, setImagesInfo] = useState([])
  const [loading, setLoading] = useState(false)

  const animationStart = (btn, action) => {
    if (btn === 'pics') {
      setStarAnimationClass(
        action === 'in' ?
          ['turn-around', ''] :
          ['', '']
      )
    } else {
      setStarAnimationClass(
        action === 'in' ?
          ['', 'turn-around'] :
          ['', '']
      )
    }
  }

  const getImages = async () => {
    midiaType = 'image'
    setOpenDialog(true)
    if (imagesInfo.length === 0) {
      setLoading(true)
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.imgur.com/3/album/N6EOsK9',
        headers: {
          'Authorization': 'Client-ID 47cd967f7e9eb1a',
          'Cookie': 'frontpagebetav2=1; postpagebeta=1'
        }
      };

      await axios.request(config, { referrer: "" })
        .then((response) => {
          processImages(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const processImages = (data) => {
    const auxImagesInfo = []
    data.images.forEach((item, index) => {
      auxImagesInfo.push({
        id: item.id,
        link: item.link,
      })

      if (index + 1 === data.images.length) {
        setImagesInfo(auxImagesInfo)
        setLoading(false)
      }
    })
  }

  const getVideos = async () => {
    midiaType = 'video'
    setOpenDialog(true)
  }

  const changeVideo = () => {
    if (videoElement !== null)
      videoElement.target.stopVideo()
  }

  return (
    <div id="midia" className="midia-content">
      <h2>Mídia</h2>
      <div className={isMobile ? 'midia-options-mobile' : 'midia-options'}>
        <button
          className="midia-options-btn"
          type="button"
          onMouseEnter={() => animationStart('pics', 'in')}
          onMouseLeave={() => animationStart('pics', 'out')}
          onClick={getImages}
        >
          <div>
            <span className={starAnimationClass[0]}>
              <FontAwesomeIcon icon={faStar} style={{ color: "#c0ff00", }} />
            </span>
            <span>Fotos</span>
            <span className={starAnimationClass[0]}>
              <FontAwesomeIcon icon={faStar} style={{ color: "#c0ff00", }} />
            </span>
          </div>
        </button>
        <button
          className="midia-options-btn"
          type="button"
          onMouseEnter={() => animationStart('videos', 'in')}
          onMouseLeave={() => animationStart('videos', 'out')}
          onClick={getVideos}
        >
          <div>
            <span className={starAnimationClass[1]}>
              <FontAwesomeIcon icon={faStar} style={{ color: "#c0ff00", }} />
            </span>
            <span>Vídeos</span>
            <span className={starAnimationClass[1]}>
              <FontAwesomeIcon icon={faStar} style={{ color: "#c0ff00", }} />
            </span>
          </div>
        </button>
      </div>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => { }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#000' }}>
          <Toolbar>
            <button
              type="button"
              className="btn-default"
              onClick={() => {
                videoElement = null
                setOpenDialog(false)
              }}
            >
              Fechar
            </button>
          </Toolbar>
        </AppBar>
        <DialogContent className={midiaType === 'image' && isMobile ? 'dialog-content-mobile' : 'dialog-content'}>
          {loading && <Loading />}
          {openDialog && !loading && imagesInfo.length > 0 && midiaType === 'image' && (
            <Carousel navButtonsAlwaysVisible >
              {
                imagesInfo.map((item, i) => {
                  return (
                    <div key={`image-${i}-${item.id}`} className="pic-carousel">
                      <img
                        className={isMobile ? 'images-carousel-mobile' : 'images-carousel'}
                        src={item.link}
                        alt={item.id}
                        referrerPolicy="no-referrer"
                      />
                    </div>
                  )
                })
              }
            </Carousel>
          )}
          {openDialog && !loading && videoLinks.length > 0 && midiaType === 'video' && (
            <Carousel navButtonsAlwaysVisible autoPlay={false} onChange={changeVideo} >
              {
                videoLinks.map((item, i) => {
                  return (
                    <div key={`video-${i}`} className="pic-carousel">
                      {/* <iframe
                        width="315"
                        height={isMobile ? '500' : '560'}
                        src={item}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe> */}
                      <YouTube
                        videoId={item.id}
                        opts={{
                          height: isMobile ? '500' : '560',
                          width: '315',
                          playerVars: {
                            autoplay: 0
                          }
                        }}
                        onPlay={(e) => { videoElement = e }}
                        origin="https://www.youtube.com"
                      />
                    </div>
                  )
                })
              }
            </Carousel>
          )}
        </DialogContent>
      </Dialog>

    </div>
  )
}

export default Midia
