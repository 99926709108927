import React from 'react'
import './Presentation.css'

const Presentation = () => {
  return (
    <div className="presentation-content">
      <div>
        <h2>A sonoridade que define os anos <span>90</span>,</h2>
        <h2>Explorando a fusão <span>eletrizante</span></h2>
        <h2>entre <span>Hard Rock</span> e <span>Glam Rock</span>.</h2>
        <h2>nós somos a <span>Harvalt</span>!</h2>
      </div>
      <div>
        <div className="back-tape"><span>&nbsp;</span></div>
        <div className="front-tape">
          <div className="list">
            <div className="item"><span>POLICE LINE DO NOT CROSS<span>&nbsp;</span><span>&nbsp;</span></span><span>&nbsp;</span></div>
            <div className="item"><span>POLICE LINE DO NOT CROSS<span>&nbsp;</span><span>&nbsp;</span></span><span>&nbsp;</span></div>
            <div className="item"><span>POLICE LINE DO NOT CROSS<span>&nbsp;</span><span>&nbsp;</span></span><span>&nbsp;</span></div>
            <div className="item"><span>POLICE LINE DO NOT CROSS<span>&nbsp;</span><span>&nbsp;</span></span><span>&nbsp;</span></div>
          </div>
          <div className="list">
          </div>
        </div>
      </div>
    </div>
  )
}

export default Presentation
