import React, { useState, useEffect } from 'react'
import { MenuItems } from './MenuItems'
import './Navbar.css'

const Navbar = () => {
  const [click, setClick] = useState(false)
  const [navbarClass, setNavbarClass] = useState('navbar')
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  useEffect(() => {
    const checkScroll = () => {
      if (window.innerWidth > 960) {
        if (prevScrollPos === 0) setNavbarClass('navbar')

        const handleScroll = () => {
          const currentScrollPos = window.scrollY

          if (currentScrollPos > prevScrollPos) {
            setNavbarClass('navbar')
          } else if (currentScrollPos < prevScrollPos) {
            setNavbarClass('navbar-fixed')
          }

          setPrevScrollPos(currentScrollPos)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
      } else {
        setNavbarClass('navbar')
        return false
      }
    }

    checkScroll()
  }, [prevScrollPos])

  const handleClick = () => setClick(!click)

  const navigation = (id) => {
    setClick(false)
    const elem = document.getElementById(id)

    elem.scrollIntoView({ behavior: 'smooth' })
    setNavbarClass('navbar')
  }

  return (
    <>
      <nav id="header-navbar" className={navbarClass}>
        <div className="navbar-logo">
          <img src="assets/images/logo_green.png" alt="logo" />
        </div>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((item, index) => {
            return (
              <li
                key={`menu-items-${index}`}
                id={`menu-item-${item.id}`}
                className="nav-item"
                /* onClick={closeMobileMenu} */
                onClick={() => navigation(item.id)}
              >
                {item.title}
              </li>
            )
          })}
        </ul>
      </nav>
    </>
  )
}

export default Navbar