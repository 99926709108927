import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MembersInfo } from './MembersInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import './Members.css'

const Members = () => {
  const [isMobile] = useState(window.innerWidth < 960)
  const [classNameMember, setClassNameMember] = useState(new Array(MembersInfo.length).fill('member-name'))
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [membersPics, setMembersPics] = useState([])

  useEffect(() => {
    const getMembersPics = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.imgur.com/3/album/GJiKyGf',
        headers: {
          'Authorization': 'Client-ID 47cd967f7e9eb1a',
          'Cookie': 'frontpagebetav2=1; postpagebeta=1'
        }
      };

      await axios.request(config, { referrer: "" })
        .then((response) => {
          processImages(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    getMembersPics()
  }, [])

  const processImages = (data) => {
    const auxMembersPics = []
    data.images.forEach((item, index) => {
      auxMembersPics[item.title] = item.link

      if (index + 1 === data.images.length) {
        setMembersPics(auxMembersPics)
      }
    })
  }

  useEffect(() => {
    const checkScroll = () => {
      const handleScroll = () => {
        setClassNameMember(new Array(MembersInfo.length).fill('member-name'))

        setPrevScrollPos(window.scrollY)
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }

    checkScroll()
  }, [prevScrollPos])

  const showMember = (hover, index) => {
    const auxClassNameMember = new Array(MembersInfo.length).fill('member-name')

    if (hover === 'in') {
      auxClassNameMember[index] = 'member-name-selected'

      setClassNameMember(auxClassNameMember)
    } else {
      auxClassNameMember[index] = 'member-name'

      setClassNameMember(auxClassNameMember)
    }
  }

  return (
    <div id="members" className="members-content">
      <div>
        <img height="250" src="assets/images/stage.png" alt="stage-pic" />
      </div>
      <div className="info-members">
        <h2>Integrantes</h2>
        <div className="members-review">
          {MembersInfo.map((item, index) => {
            return (
              <div
                className="member"
                id={`member-${index}`}
                key={`member-${index}`}
                onMouseEnter={() => {
                  if (!isMobile) showMember('in', index)
                }}
                onMouseLeave={() => {
                  if (!isMobile) showMember('out', index)
                }}
              >
                <div>
                  <h2 className={classNameMember[index]}>{item.name}</h2>
                  <div>
                    <h4>{item.role}</h4>
                    <a className="icon-social-media" href={item.instagram} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faInstagram} size="lg" style={{ color: "#c0ff00", }} />
                    </a>
                  </div>
                </div>
                <div
                  className={isMobile ? 'member-pic-mobile' :
                    classNameMember[index] === 'member-name-selected' ? 'show-member-image cssanimation sequence fadeInBottom' : 'hidden'}
                >
                  <img src={membersPics[item.id]} alt={item.name} referrerPolicy="no-referrer" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Members
